import React from "react"
import Layout from "../components/layout/Layout"
import SecondaryHeader from "../components/sections/SecondaryHeader"
import SEO from "../components/SEO"
import seotext from "../constants/seotext"
import Events from "../components/events/Events"
import Benefits from "../components/sections/Benefits"
import Cta from "../components/Cta"
import KovoartAbout from "../components/sections/KovoartAbout"
import { graphql, useStaticQuery } from "gatsby"

export default ({ pageContext }) => {

  const image = useStaticQuery(graphql`
    query {
        calendar: contentfulAsset(title: { eq: "CANR7996" }) {
          fluid(maxWidth: 1600, quality: 50) {
            ...GatsbyContentfulFluid
          }
        }
      }
    `)

  return (
    <Layout>
      <SEO title="Kalendář akcí" description={seotext.kalendar} />
      <SecondaryHeader
        pageContext={pageContext}
        disable={["/kalendar"]}
        heading="Kalendář"
        bold="Akcí"
        image={image.calendar.fluid}
      />
      <Events />
      <KovoartAbout />
      <Cta />
      <Benefits />
    </Layout>
  )
}
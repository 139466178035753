import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./Events.module.scss"
import Event from "./Event"

const getEvents = graphql`
  query {
    allContentfulKalendarAkci(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          event
          eventDesc
          day
          monthyear
          id
        }
      }
    }
  }
`

const Events = () => {
  const data = useStaticQuery(getEvents).allContentfulKalendarAkci.edges  
  const events = data.forEach(ev => ev.node.event !== 'test')

  return (
    <section>
      <div className="container-small">
        <div className={styles.events}>
          {events && events.map(event => (
                <Event
                  key={event.node.id}
                  title={event.node.event}
                  desc={event.node.eventDesc}
                  day={event.node.day}
                  month={event.node.monthyear}
                />
              ))
            }
        </div>
        <div className={styles.empty}>
          V nejbližsí době nejsou náplánované žádné akce.
        </div>
      </div>
    </section>
  )
}

export default Events

import React from "react"
import styles from "./Event.module.scss"
import Fade from "react-reveal/Fade"
import PropTypes from 'prop-types';

const Event = ({ title, day, month }) => {
  return (
      <div className={styles.event}>
          <div className={styles.date}>
            <Fade bottom duration={700}><div className={styles.day}>{day}</div></Fade>
            <Fade bottom duration={700}><div>{month}</div></Fade>
          </div>
        <div className={styles.text}>
        <Fade bottom duration={700}>
          <h3 className={styles.title}>{title}</h3>
        </Fade>
        <Fade bottom duration={700}>
          <p className={styles.description}>
            Reprehenderit non ex deserunt sint nulla. Fugiat amet laboris minim
            et elit amet elit est minim do sunt incididunt proident dolor. Ex
            occaecat ex velit aliquip aliqua commodo aliquip officia
            consectetur.
          </p>
        </Fade>
        </div>
        <div className={styles.image}></div>
      </div>
  )
}

export default Event

Event.propTypes = {
  title: PropTypes.string,
  day: PropTypes.string,
  month: PropTypes.string
}
